export default {
  computed: {
    navs() {
      console.log('路由信息', this.$route.matched);
      let routes = this.$route.matched;
      //处理第一个路由的信息
      routes[0].path = '/';
      return routes;
    }
  },
  created() {
    console.log(this.$route); //包含当前的路由信息 以及上级路由信息 
  }
};