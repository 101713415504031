import { createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-585bdcd4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "menu"
};
import Menu from './menu/Menu.vue';
import Main from './content/Main.vue';
import { ref } from 'vue';
export default {
  __name: 'Index',
  setup(__props) {
    const isCollapse = ref(false);
    const changeCollapse = () => {
      isCollapse.value = !isCollapse.value;
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(Menu, {
        isCollapse: isCollapse.value
      }, null, 8, ["isCollapse"])]), _createElementVNode("div", {
        class: _normalizeClass(["content", {
          isClose: isCollapse.value
        }])
      }, [_createVNode(Main, {
        isCollapse: isCollapse.value,
        onChangeCollapse: changeCollapse
      }, null, 8, ["isCollapse"])], 2)], 64);
    };
  }
};